<template>
    <v-container fluid>
        <h1>ESTUDIO NÓMINAS</h1>
        <v-simple-table>
            <thead>
                <tr>
                    <th class="text-center"></th>
                    <th class="text-center">HOY</th>
                    <th class="text-center">1ª PPTA</th>
                    <th class="text-center">2ª PPTA</th>
                    <th class="text-center">PPTA BATIS</th>
                    <th class="text-center">MIX</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>NETO</td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.netoHoyA"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.netoPrimeraA"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.netoSegundaA"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.netoBatisA"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.netoMixA"></v-text-field></td>
                </tr>
                <tr>
                    <td>IRPF</td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.irpfHoyA"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.irpfPrimeraA"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.irpfSegundaA"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.irpfBatisA"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.irpfMixA"></v-text-field></td>
                </tr>
            </tbody>
            <thead>
                <tr>
                    <th class="text-center"></th>
                    <th class="text-center">HOY</th>
                    <th class="text-center">1ª PPTA</th>
                    <th class="text-center">2ª PPTA</th>
                    <th class="text-center">PPTA BATIS</th>
                    <th class="text-center">MIX</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>SS C/TRABAJADOR</td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.pntSsctrabHoyB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.pntSsctrabPrimeraB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.pntSsctrabSegundaB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.pntSsctrabBatisB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.pntSsctrabMixB"></v-text-field></td>
                </tr>
                <tr>
                    <td>IRPF</td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.pntIrpfHoyB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.pntIrpfPrimeraB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.pntIrpfSegundaB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.pntIrpfBatisB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.pntIrpfMixB"></v-text-field></td>
                </tr>
                <tr>
                    <td>SS C/EMPRESA</td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.pntSscempresaHoyB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.pntSscempresaPrimeraB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.pntSscempresaSegundaB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.pntSscempresaBatisB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.pntSscempresaMixB"></v-text-field></td>
                </tr>
                <tr></tr>
                <tr>
                    <td>SS C/TRABAJADOR</td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.ssctrabHoyB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.ssctrabPrimeraB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.ssctrabSegundaB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.ssctrabBatisB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.ssctrabMixB"></v-text-field></td>
                </tr>
                <tr>
                    <td>IRPF</td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.irpfHoyB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.irpfPrimeraB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.irpfSegundaB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.irpfBatisB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.irpfMixB"></v-text-field></td>
                </tr>
                <tr>
                    <td>SS C/EMPRESA</td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.sscempresaHoyB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.sscempresaPrimeraB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.sscempresaSegundaB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.sscempresaBatisB"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.sscempresaMixB"></v-text-field></td>
                </tr>
            </tbody>
            <thead>
                <tr>
                    <th class="text-center">ESTUDIOS</th>
                    <th class="text-center">HOY</th>
                    <th class="text-center">1ª PPTA</th>
                    <th class="text-center">2ª PPTA</th>
                    <th class="text-center">PPTA BATIS</th>
                    <th class="text-center">MIX</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>BRUTO</td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.brutoHoyC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.brutoPrimeraC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.brutoSegundaC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.brutoBatisC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.brutoMixC"></v-text-field></td>
                </tr>
                <tr>
                    <td>NETO</td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.netoHoyC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.netoPrimeraC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.netoSegundaC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.netoBatisC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.netoMixC"></v-text-field></td>
                </tr>
                <tr>
                    <td>COSTE EMPRESA</td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.empresaHoyC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.empresaPrimeraC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.empresaSegundaC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.empresaBatisC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.empresaMixC"></v-text-field></td>
                </tr>
            </tbody>
            <thead>
                <tr>
                    <th class="text-center">DIF €</th>
                    <th class="text-center">HOY</th>
                    <th class="text-center">1ª PPTA</th>
                    <th class="text-center">2ª PPTA</th>
                    <th class="text-center">PPTA BATIS</th>
                    <th class="text-center">MIX</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>BRUTO</td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difEurBrutoHoyC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difEurBrutoPrimeraC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difEurBrutoSegundaC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difEurBrutoBatisC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difEurBrutoMixC"></v-text-field></td>
                </tr>
                <tr>
                    <td>NETO</td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difEurNetoHoyC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difEurNetoPrimeraC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difEurNetoSegundaC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difEurNetoBatisC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difEurNetoMixC"></v-text-field></td>
                </tr>
                <tr>
                    <td>COSTE EMPRESA</td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difEurEmpresaHoyC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difEurEmpresaPrimeraC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difEurEmpresaSegundaC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difEurEmpresaBatisC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difEurEmpresaMixC"></v-text-field></td>
                </tr>
            </tbody>
            <thead>
                <tr>
                    <th class="text-center">DIF %</th>
                    <th class="text-center">HOY</th>
                    <th class="text-center">1ª PPTA</th>
                    <th class="text-center">2ª PPTA</th>
                    <th class="text-center">PPTA BATIS</th>
                    <th class="text-center">MIX</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>BRUTO</td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difPntBrutoHoyC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difPntBrutoPrimeraC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difPntBrutoSegundaC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difPntBrutoBatisC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difPntBrutoMixC"></v-text-field></td>
                </tr>
                <tr>
                    <td>NETO</td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difPntNetoHoyC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difPntNetoPrimeraC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difPntNetoSegundaC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difPntNetoBatisC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difPntNetoMixC"></v-text-field></td>
                </tr>
                <tr>
                    <td>COSTE EMPRESA</td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difPntEmpresaHoyC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difPntEmpresaPrimeraC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difPntEmpresaSegundaC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difPntEmpresaBatisC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.difPntEmpresaMixC"></v-text-field></td>
                </tr>
            </tbody>
            <thead>
                <tr>
                    <th class="text-center">EJEMPLO BODA</th>
                    <th class="text-center">HOY</th>
                    <th class="text-center">1ª PPTA</th>
                    <th class="text-center">2ª PPTA</th>
                    <th class="text-center">PPTA BATIS</th>
                    <th class="text-center">MIX</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>NETO</td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.ejemploNetoHoyC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.ejemploNetoPrimeraC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.ejemploNetoSegundaC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.ejemploNetoBatisC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.ejemploNetoMixC"></v-text-field></td>
                </tr>
                <tr>
                    <td>COSTE EMPRESA</td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.ejemploCosteEmpresaHoyC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.ejemploCosteEmpresaPrimeraC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.ejemploCosteEmpresaSegundaC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.ejemploCosteEmpresaBatisC"></v-text-field></td>
                    <td><v-text-field hide-details="" dense class="v-superdense-input" v-model="nomina.ejemploCosteEmpresaMixC"></v-text-field></td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-container>
</template>

<script>
export default {
    data(){
        return {
            nomina: {}
        }
    }
}
</script>